<template>
  <div v-if="list_visibility">
    <div class="mb-4 grid grid-cols-2">
      <aside>
        <button class="inline-block p-2 text-xl mr-2" @click="hide()">
          <fa-icon icon="angle-up"></fa-icon>
        </button>

        <span class="text-xl mb-6 uppercase tracking-wide"> Jadwal </span>
      </aside>

      <!-- Button trigger -->
      <aside class="text-right" v-if="selected_period.is_active">
        <!-- trigger add new period -->
        <button class="button --primary ml-4" @click="SHOW_FORM()">
          <fa-icon icon="plus"></fa-icon>
          <span class="ml-2">Jadwal</span>
        </button>
      </aside>
    </div>

    <!-- schedule list items -->
    <div class="p-2 text-center uppercase tracking-wider" v-if="!hasSchedule">
      Belum ada jadwal
    </div>
    <div class="sm:grid sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
      <schedule-list-item
        v-for="(item, key) in schedules"
        :key="key"
        :schedule="item"
        class="bg-white rounded mb-4"
      ></schedule-list-item>
    </div>

    <schedule-form></schedule-form>
  </div>
</template>

<style lang="scss" scoped>
.schedule-box {
  @apply p-4 shadow mb-6;

  .actions {
    @apply text-right p-2 mb-2;

    .button {
      @apply mx-1;
    }
  }
}

.schedule-box__info {
  @apply grid grid-cols-2  gap-4;

  .label,
  .time-difference {
    @apply block uppercase text-sm tracking-wide text-gray-600;
  }

  .content {
    @apply text-base;
  }
}
</style>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {
    hide() {
      this.UNSET_SELECTED_PERIOD();
      this.HIDE_MEMBER_LIST();
      this.HIDE_LIST();
      // show previously hidden period items
      EventBus.$emit("period.item.show");
    },
    ...mapMutations("period", ["UNSET_SELECTED_PERIOD"]),
    ...mapMutations("schedule", [
      "HIDE_LIST",
      "SHOW_FORM",
      "SET_SELECTED_SCHEDULE",
    ]),

    /** Mutations from member store */
    ...mapMutations("member", {
      HIDE_MEMBER_LIST: "HIDE_LIST",
    }),
  },
  computed: {
    hasSchedule() {
      return this.schedules.length > 0;
    },
    ...mapGetters("period", ["selected_period"]),
    ...mapGetters("schedule", [
      "schedules",
      "selected_schedule",
      "list_visibility",
    ]),
  },
  name: "ScheduleList",
  components: {
    ScheduleForm: () =>
      import("@/components/Registration/Schedule/ScheduleForm.vue"),
    ScheduleListItem: () =>
      import("@/components/Registration/Schedule/ScheduleListItem.vue"),
  },
};
</script>